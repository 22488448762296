import { graphql } from 'gatsby';
import * as React from 'react';
import { Content } from '../components/content/content';
import { Header } from '../components/header/header';
import { Logo } from '../components/logo/logo';
import { Main } from '../components/main/main';
import { layout } from '../pages/index.module.css';

export default function Template(page) {
  const tag = page.pageContext.tag;
  const posts = page.data.allMarkdownRemark.edges;

  const articleData = posts.map((x, i) => ({
    slug: x.node.frontmatter.slug,
    date: x.node.frontmatter.date,
    tags: x.node.frontmatter.tags,
    title: x.node.frontmatter.title,
    featuredImage:
      x.node.frontmatter.featuredImage?.childImageSharp.fluid,
    html:
      x.node.html
        .slice(0, 180)
        .replace(/<p>/g, '')
        .replace('</p>', '')
        .replace(/<code>/g, '')
        .replace('</code>', '') + '...',
  }));

  return (
    <div className={layout}>
      <Header>
        <Logo tag={tag} />
      </Header>
      <Content>
        <Main {...articleData}></Main>
      </Content>
    </div>
  );
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            tags
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
